import { CogniteClient} from '@cognite/sdk';
import {  PublicClientApplication } from "@azure/msal-browser";


const CDF_CLUSTER = process.env.REACT_APP_CDF_CLUSTER 
const CDF_PROJECT = process.env.REACT_APP_CDF_PROJECT 
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const TENANT_ID = process.env.REACT_APP_TENANT_ID
const BASE_URL = `https://${CDF_CLUSTER}.cognitedata.com`

const scopes = [
  `${BASE_URL}/DATA.VIEW`,
  `${BASE_URL}/IDENTITY`,
  `${BASE_URL}/user_impersonation`
];

export const appConfig = () => ({
  appId: 'OMV Flow Rates app',
  project: CDF_PROJECT,
  configExternalId: 'flow_rates_application_config',
  baseUrl: BASE_URL
});

const config = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}` ,
  },
};

export const pca = new PublicClientApplication(config);


export const getToken = async () => {
  const accountId = sessionStorage.getItem("account");
  const account = pca.getAccountByLocalId(accountId);
  let token = ""
  if (!account) {
    token = await pca.acquireTokenPopup({
      account,
      scopes,
    });
  } else {//throw new Error("no user found");
    token = await pca.acquireTokenSilent({
      account,
      scopes,
    }).catch(e => {
      return pca.acquireTokenPopup({
        account,
        scopes,
      });
    });
  }

  return token.accessToken;
};

export const logIn = async () => {
  const { project, appId, baseUrl } = appConfig();
  const client = new CogniteClient({
    project: project,
    appId: appId,
    baseUrl: baseUrl,
    getToken
  });

  await client.authenticate();
  return client
};

const fetchBaseConfig = async (client) => {
  const externalId = appConfig().configExternalId;
  const url = await client.files.getDownloadUrls([{ externalId }]); //await
  const baseUrl = url[0].downloadUrl;
  return {
    ...(
      await fetch(
        baseUrl,
        { method: 'GET' }
      ).then(response => response.json())
    ),
    ...appConfig()
  };
};

const fetchModelParameters = async (client, baseConfig, configAmendmentId = 'modelParametersExternalId') => {
  const externalId = baseConfig[configAmendmentId];
  if (!externalId) {
    console.warn(`No such externalId: ${configAmendmentId}`);
    return {}
  }
  try {
    const url = await client.files.getDownloadUrls([{ externalId }]);
    const baseUrl = url[0].downloadUrl;
    const body = await fetch(
      baseUrl,
      { method: 'GET' }
    ).then(response => response.json());
    return body;
  } catch (e) {
    console.warn(e.message);
    return undefined;
  }
};

export const initConfiguration = async (client = undefined) => {
  if (client) {
    const baseConfig = await fetchBaseConfig(client);
    const modelParameters = await fetchModelParameters(client, baseConfig);
    window.globalState.config = { baseConfig, modelParameters };
  }
};

const configuration = () => {
  if (!window.globalState.config) return undefined;
  const { baseConfig, modelParameters } = window.globalState.config;
  return baseConfig && {
    ...baseConfig,
    modelParameters: {
      ...baseConfig.modelParameters,
      ...modelParameters
    }
  };
};

export default configuration;
